import { BrowserView, MobileView } from "react-device-detect"
import ProductBox from "../../Components/Elements/product_box"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const Shop=()=>{
    return(<>
    <BrowserView>
    <Header></Header>
    <p>Shop</p>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <Footer></Footer>
    </BrowserView>
   <MobileView>
   <Header></Header>
    <p>Shop</p>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <ProductBox></ProductBox>
    <Footer></Footer>
   </MobileView>
    </>)
}

export default Shop