
import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Service/apiservices";
import constant from "../../Components/Service/constant";

const BlogDetail=()=>{
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const navigate = useNavigate()
    const [blogsData, setblogData] = useState({})
    const [blogsUrl, setblogsUrl] = useState({nextBlogName:'', next_blog_url:'', previousBlogName:'', previous_blog_url:""})
    const [relatedblogsList, setrelatedblogsList] = useState([])
    const [blogscateData, setblogcateData] = useState([])
    const [blogimgUrl, setblogimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            getblogsdetailData()

        }
        didMountRef.current = false;
    }, []);
    const getblogsdetailData = () => {
        setisLoading(true)
        const dataString = {
            blog_slug: slug
        }
        ApiService.postData('/blog-details', dataString).then((res) => {
            if (res.status == 'success') {
                setblogimgUrl(res.blog_image_path)
                setblogsUrl({
                    nextBlogName:res.nextBlogName,
                    next_blog_url:res.next_blog_url,
                    previousBlogName:res.previousBlogName,
                    previous_blog_url:res.previous_blog_url
                })
                setblogcateData(res.categoryData)
                setblogData(res.data)
                setisLoading(false)
                setrelatedblogsList(res.relatedBlogs)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }

    return(<>
    <BrowserView>
    <Header></Header>
    {blogsData ?<>
        <section className="section-gap-medium ">
      <div className="container">
        <div className="row">
            <p>{blogsData?.blog_author} {moment(blogsData.created_at).format('DD MMMM YYYY')}</p>
        <h6>{blogsData?.blog_name}</h6>
        <img src={blogsData.blog_image && blogimgUrl + blogsData.blog_image } alt={blogsData?.blog_name}></img>
        <div dangerouslySetInnerHTML={{__html:blogsData?.blog_desc}}></div>
       
       
        {blogsUrl.previousBlogName && (  <div><a href={`/blog/${blogsUrl.previous_blog_url}`}>Previous</a><p>{blogsUrl.previousBlogName}</p> </div>)}
        {blogsUrl.nextBlogName && (  <div><a href={`/blog/${blogsUrl.next_blog_url}`}>Next</a><p>{blogsUrl.nextBlogName}</p> </div>)}
        </div>
      </div>
    </section>
    </>:null}
 
  <Footer></Footer>
    </BrowserView>
<MobileView>
<Header></Header>
{blogsData? <>
    <section className="section-gap-medium ">
      <div className="container">
        <div className="row">
            <p>{blogsData?.blog_author} {moment(blogsData.created_at).format('DD MMMM YYYY')}</p>
        <h6>{blogsData?.blog_name}</h6>
        <img src={blogsData.blog_image && blogimgUrl + blogsData.blog_image } alt={blogsData?.blog_name}></img>
        <div dangerouslySetInnerHTML={{__html:blogsData?.blog_desc}}></div>
       
       
        {blogsUrl.previousBlogName && (  <div><a href={`/blog/${blogsUrl.previous_blog_url}`}>Previous</a><p>{blogsUrl.previousBlogName}</p> </div>)}
        {blogsUrl.nextBlogName && (  <div><a href={`/blog/${blogsUrl.next_blog_url}`}>Next</a><p>{blogsUrl.nextBlogName}</p> </div>)}
 
       
        </div>
      </div>
    </section>
</>:null}
 
  <Footer></Footer>
</MobileView>
    
    </>)
}

export default BlogDetail