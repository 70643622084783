import { ApiService } from "../Service/apiservices"
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Accordion from 'react-bootstrap/Accordion';
import DataContext from "../Elements/context";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

const Footer = () => {
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const { settingData } = useContext(DataContext)
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [newsletteremail, setnewsletteremail] = useState("");
    const [footerData, setFooterData] = useState({})
    const [isloading, setisloading] = useState(false)
    const [footerData1, setFooterData1] = useState({})
    const [footerData2, setFooterData2] = useState({})
    const [footerData3, setFooterData3] = useState({})
    const [footerData4, setFooterData4] = useState({})
    const [footerData5, setFooterData5] = useState({})
    const [footerData6, setFooterData6] = useState({})
    useEffect(() => {
        if (didMountRef.current) {
            setisloading(true)
            ApiService.fetchData('footer').then(result => {
                if (result.status == 'success') {

                    setFooterData(result.footerIdData)
                    setFooterData1(result.footerIdData?.footer_desc1)
                    setFooterData2(result.footerIdData?.footer_desc2)
                    setFooterData3(result.footerIdData?.footer_desc3)

                    setTimeout(() => {
                        setisloading(false)
                    }, [500])

                }
                else {
                    setTimeout(() => {
                        setisloading(false)
                    }, [500])
                }

            }).catch(() => {
                setTimeout(() => {
                    setisloading(false)
                }, [500])
            })

        }
        didMountRef.current = false;
    }, []);

    const newsLetter = (e) => {
        setErrorMessage("");
        if (newsletteremail == "" || newsletteremail == null) {
            setErrorMessage('Please enter email address')
            setTimeout(() => {
                setErrorMessage('')
            }, [2000])
            return;
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletteremail)) {
            const email = newsletteremail.toLowerCase();
            let datastring = { newsletter_email: email };
            ApiService.postData('/newsletter-process', datastring).then((res) => {
                if (res?.status == "success") {
                    setSuccessMessage(res?.message)
                    setnewsletteremail("");
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, [2000])

                } else {
                    setErrorMessage(res?.message)
                    setnewsletteremail("");
                    setTimeout(() => {
                        setErrorMessage('')
                    }, [2000])
                }
            });
        } else {
            setErrorMessage("Invalid email address. Please enter valid email")
            setTimeout(() => {
                setErrorMessage('')
            }, [2000])
            return;
        }
    };

    return (<>
        <BrowserView>
            <footer className="footer">
                <div className="footer-top">
                    <div className="container-fluid">
                        <div className="row">
                            {isloading ? <>
                                {[...Array(4)].map((item, index) => (
                                    <div key={index} className="col-lg-3">
                                        <h2 className="footer-title"><Skeleton width={150} /></h2>
                                        <div >
                                            <ul className="footer-list">
                                                <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                                <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                                <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>

                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </> : <>
                                <div className="col-lg-4">
                                    <a href="/" className="footer-logo mb-20">
                                        <img src="/img/logo2.png"></img>
                                    </a>
                                    {footerData1 && (<div dangerouslySetInnerHTML={{ __html: footerData1 }}></div>)}
                                </div>
                                {footerData2 && (<div className="col-lg-2">

                                    <div dangerouslySetInnerHTML={{ __html: footerData2 }}></div>
                                </div>)}
                                {footerData3 && (<div className="col-lg-2">

                                    <div dangerouslySetInnerHTML={{ __html: footerData3 }}></div>
                                </div>)}
                                <div class="col-lg-4">
                                    <div ><h2 className="footer-title">Subscribe to Our News Letter</h2></div>
                                    <p>Enter your email address to register to our newsletter subscription</p>
                                    {successMessage && <div class="alert alert-success" role="alert">{successMessage}</div>}
                                    {errorMessage && <div class="alert alert-danger" role="alert">{errorMessage}</div>}
                                    <div class="subscribe-form mb-4">
                                        <input class="email newsletterRequired" type="email" placeholder="Enter your email here.." name="newsletter_email" value={newsletteremail}
                                            onChange={(e) => setnewsletteremail(e.target.value)} />
                                        <div class="subscribe-button"><input class="button" type="button" name="subscribe" value="Subscribe" onClick={(e) => { newsLetter(e) }} /></div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom text-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <img src="/img/paymentsfooter.png" className="img-fluid mb-20"></img>
                                <p>St. James Supply Co. © 2024. All Rights Reserved</p>
                                <ul className="footer-social">
                                    {settingData.instagram_url && (
                                        <li><a href={settingData.instagram_url} target='_blank'><i className="ri-instagram-line"></i></a></li>)}
                                    {settingData.facebook_url && (
                                        <li><a href={settingData.facebook_url} target='_blank'><i className="ri-facebook-fill"></i></a></li>)}
                                    {settingData.linkedin_url && (
                                        <li><a href={settingData.linkedin_url} target='_blank'><i className="ri-linkedin-fill"></i></a></li>)}
                                    {settingData.twitter_url && (
                                        <li><a href={settingData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
                                    {settingData.youtube_url && (
                                        <li><a href={settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </BrowserView>
        <MobileView>

            <footer className="footer">
                <div className="footer-top">
                    <div className="container-fluid">
                        <div className="row">
                            {isloading ? <>
                                {[...Array(4)].map((item, index) => (
                                    <div key={index} className="col-lg-3">
                                        <h2 className="footer-title"><Skeleton width={150} /></h2>
                                        <div >
                                            <ul className="footer-list">
                                                <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                                <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                                <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>

                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </> : <>
                                <div className="col-lg-4">
                                    <a href="/" className="footer-logo mb-20">
                                        <img src="/img/logo2.png"></img>
                                    </a>
                                    {footerData1 && (<div dangerouslySetInnerHTML={{ __html: footerData1 }}></div>)}
                                </div>
                                {footerData2 && (<div className="col-lg-2">

                                    <div dangerouslySetInnerHTML={{ __html: footerData2 }}></div>
                                </div>)}
                                {footerData3 && (<div className="col-lg-2">

                                    <div dangerouslySetInnerHTML={{ __html: footerData3 }}></div>
                                </div>)}
                                <div class="col-lg-4">
                                    <div ><h2 className="footer-title">Subscribe to Our News Letter</h2></div>
                                    <p>Enter your email address to register to our newsletter subscription</p>
                                    {successMessage && <div class="alert alert-success" role="alert">{successMessage}</div>}
                                    {errorMessage && <div class="alert alert-danger" role="alert">{errorMessage}</div>}
                                    <div class="subscribe-form mb-4">
                                        <input class="email newsletterRequired" type="email" placeholder="Enter your email here.." name="newsletter_email" value={newsletteremail}
                                            onChange={(e) => setnewsletteremail(e.target.value)} />
                                        <div class="subscribe-button"><input class="button" type="button" name="subscribe" value="Subscribe" onClick={(e) => { newsLetter(e) }} /></div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom text-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <img src="/img/paymentsfooter.png" className="img-fluid mb-20"></img>
                                <p>St. James Supply Co. © 2024. All Rights Reserved</p>
                                <ul className="footer-social">
                                    {settingData.instagram_url && (
                                        <li><a href={settingData.instagram_url} target='_blank'><i className="ri-instagram-line"></i></a></li>)}
                                    {settingData.facebook_url && (
                                        <li><a href={settingData.facebook_url} target='_blank'><i className="ri-facebook-fill"></i></a></li>)}
                                    {settingData.linkedin_url && (
                                        <li><a href={settingData.linkedin_url} target='_blank'><i className="ri-linkedin-fill"></i></a></li>)}
                                    {settingData.twitter_url && (
                                        <li><a href={settingData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
                                    {settingData.youtube_url && (
                                        <li><a href={settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </MobileView>

    </>)
}

export default Footer