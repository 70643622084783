import React, { createContext, useEffect, useState, useRef } from 'react';
import { ApiService } from '../Service/apiservices';
const DataContext = createContext();
export const DataProvider = ({ children }) => {
  const [settingData, setSettingData] = useState({});
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem("USER_TOKEN"));
  const didMountRef = useRef(true);


  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
     
    }
    didMountRef.current = false;
  }, [])



  const getSettingData = () => {
    try {
      ApiService.fetchData('/settingsdata').then((res) => {
        if (res.status === 'success') {
          setSettingData(res.sitesettings)
          setSettingImageBaseUrl(res.setting_image_path)
        }
      })
    } catch (error) {
      console.error('An error occurred while fetching blog data:', error);
    }
  }

  return (
    <DataContext.Provider value={
      {
        settingData, setSettingData,
        settingImageBaseUrl, setSettingImageBaseUrl,
        userToken, setUserToken,
        toggleMenuModal,setToggleMenuModal
       
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;