import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'remixicon/fonts/remixicon.css';
import "swiper/css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Container/Home';
import Pages from './Container/Pages';
import ContactUs from './Container/ContactUs';
import PageNotFound from './Container/Pages/page_not_found';
import Blogs from './Container/Blogs';
import AboutUs from './Container/About-us';
import Shop from './Container/Shop';
import BlogDetail from './Container/BlogDetail';
import FAQ from './Container/FAQ';
function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:slug" element={<Pages />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/blog/:slug" element={<BlogDetail />} />
      <Route path="/error_404" activeClassName="active" element={<PageNotFound />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
  </Router>
  );
}

export default App;
