
import Accordion from 'react-bootstrap/Accordion';
import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Service/apiservices";
import DataContext from "../../Components/Elements/context";
import constant from "../../Components/Service/constant"
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';




const FAQ=()=>{
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const [faqData, setfaqData] = useState([])
    const [faqcatwiseData, setfaqcatwiseData] = useState([])
    const [faqimgUrl, setfaqimgUrl] = useState('')
    const [addActive, setaddActive] = useState('')
    const [isLoading, setisLoading] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            getfaqData()

        }
        didMountRef.current = false;
    }, []);
    const getfaqData = () => {
        setisLoading(true)
        ApiService.fetchData('faqs').then((res) => {
            if (res.status == 'success') {
                setfaqData(res.faqCatData)
                setfaqimgUrl(res.category_image_path)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }



    return(<>
    <BrowserView>
    <Header></Header>
    <section className="section-gap-medium">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="section-title mb-20">
                                    <h3>Frequently Asked Questions</h3>
                                </div>
                                {faqData && faqData.length > 0 ? <>
                                    <div className="faqaccordation">
                                        <Accordion defaultActiveKey={0}>
                                            {faqData?.map((items, index) => {
                                                return (<>
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header>{items.faq_title}</Accordion.Header>
                                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: items.faq_description }}>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>)
                                            })}
                                        </Accordion>
                                    </div>

                                </> : <div className="faqaccordation">
                                    <p >FAQ Not Found</p>
                                </div>}

                            </div>
                        </div>
                    </div>
                </section>
    <Footer></Footer>
    
    
    </BrowserView>
    <MobileView>
    <Header></Header>
    <section className="section-gap-medium">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="section-title mb-20">
                                    <h3>Frequently Asked Questions</h3>
                                </div>
                                {faqData && faqData.length > 0 ? <>
                                    <div className="faqaccordation">
                                        <Accordion defaultActiveKey={0}>
                                            {faqData?.map((items, index) => {
                                                return (<>
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header>{items.faq_title}</Accordion.Header>
                                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: items.faq_description }}>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>)
                                            })}
                                        </Accordion>
                                    </div>

                                </> : <div className="faqaccordation">
                                    <p >FAQ Not Found</p>
                                </div>}

                            </div>
                        </div>
                    </div>
                </section>
    <Footer></Footer>
    </MobileView>

    </>)
}

export default FAQ