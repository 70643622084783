import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../../Components/Service/apiservices";
import constant from "../../../Components/Service/constant";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Testimonial = () => {
  const sliderRef = useRef(null);
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  const [testimonialData, setTestimonialData] = useState([]);
  const [defaultImage, setDefaultImage] = useState('');

  useEffect(() => {
    if (didMountRef.current) {
      ApiService.fetchData("featuredTestimonialList").then((res) => {
        if (res.status === "success") {
          setTestimonialData(res.testimonialData);
          setDefaultImage(res.testimonial_image_path)
        }
      });
    }
    didMountRef.current = false;
  }, []);


  return (<>

    {testimonialData && testimonialData.length > 0 ? <>
      <section className="section-gap-medium testimonial">
        <div className="container">
          <div className="section-title text-center mb-40">
            <h2>What our customers are saying</h2>
          </div>
          <div className="row">
            <Swiper
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              ref={sliderRef}
              breakpoints={{
                360: { // For screen width 360px
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {testimonialData && testimonialData.map((testimonial, index) => (
                <SwiperSlide key={index}>

                  <div className="testimonialBox" >
                    <div className="testimonialBoxMedia">
                      <img src={testimonial.testimonial_image ? defaultImage + testimonial.testimonial_image : constant.DEFAULT_IMAGE} alt={testimonial.testimonial_name} />
                    </div>
                    <div className="testimonialBoxContent">
                      <p className="desc" dangerouslySetInnerHTML={{ __html: testimonial.testimonial_desc }}></p>
                      <h6>{testimonial.testimonial_name} , {testimonial.testimonial_location}</h6>
                    </div>
                  </div>

                </SwiperSlide>
              ))}
            </Swiper>

          </div>
        </div>
      </section>
    </> : null}


  </>)
}


export default Testimonial