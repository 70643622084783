import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../Components/Service/apiservices";
import constant from "../../Components/Service/constant";
import moment from "moment";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { BrowserView, MobileView } from "react-device-detect";


const Blog=()=>{
    const didMountRef = useRef(true)
    const navigate=useNavigate()
    const [blogsData, setblogData] = useState([])
    const [blogimgUrl, setblogimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            getblogsData()

        }
        didMountRef.current = false;
    }, []);
    const getblogsData = () => {
        setisLoading(true)
        ApiService.fetchData('/blog-list').then((res) => {
            if (res.status == 'success') {
                setblogimgUrl(res.blog_image_path)
                setblogData(res.blogsData)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
 

  
    return(<>
    <BrowserView>
    <Header></Header>
    {blogsData && blogsData.length>0 ? <>
        <section className="section-gap-medium">
      <div className="container">
        <div className="row">
            {blogsData && blogsData.map((blog , index)=>{
                return(<>
                 <div className="col-lg-4" key={index}>
            <div className="testimonialBox" onClick={()=>{navigate(`/blog/${blog?.blog_slug}`)}}>
              <div className="testimonialBoxMedia">
              <img src={blog.blog_image ? blogimgUrl + blog.blog_image : constant.DEFAULT_IMAGE} alt={blog.blog_name} />
              </div>
              <div className="testimonialBoxContent">
              <h6>{blog.blog_name} </h6>
               {blog?.blog_short_description && <p className="desc" dangerouslySetInnerHTML={{__html:blog?.blog_short_description}}></p>} 
              <p>{blog?.blog_author} {moment(blog?.created_at).format('DD MMMM YYYY')}</p> 
              </div>
            </div>
          </div>
                </>)
            })}
        </div>
      </div>
    </section>
    </>:null}
     <Footer></Footer>
    </BrowserView>
    <MobileView>

    <Header></Header>
    {blogsData && blogsData.length>0 ? <>
        <section className="section-gap-medium">
      <div className="container">
        <div className="row">
            {blogsData && blogsData.map((blog , index)=>{
                return(<>
                 <div className="col-lg-4" key={index}>
            <div className="testimonialBox" onClick={()=>{navigate(`/blog/${blog?.blog_slug}`)}}>
              <div className="testimonialBoxMedia">
              <img src={blog.blog_image ? blogimgUrl + blog.blog_image : constant.DEFAULT_IMAGE} alt={blog.blog_name} />
              </div>
              <div className="testimonialBoxContent">
              <h6>{blog.blog_name} </h6>
               {blog?.blog_short_description && <p className="desc" dangerouslySetInnerHTML={{__html:blog?.blog_short_description}}></p>} 
              <p>{blog?.blog_author} {moment(blog?.created_at).format('DD MMMM YYYY')}</p> 
              </div>
            </div>
          </div>
                </>)
            })}
        </div>
      </div>
    </section>
    </>:null}
     <Footer></Footer>
    </MobileView>
    
    </>)
}


export default Blog