import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Service/apiservices"
import Menu from "./menu";
import { BrowserView, MobileView } from "react-device-detect";
import DataContext from "../Elements/context";
import MobileMenu from "../Modals";


const Header = () => {
  const [menuData, setmenuData] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState("");

  const didMountRef = useRef(true)
  const contextValues = useContext(DataContext)
  useEffect(() => {
    if (didMountRef.current) {
      getmenuList()
    }
    didMountRef.current = false;
  }, []);

  const getmenuList = () => {
    ApiService.fetchData('/menue-list').then(result => {
      if (result.status == 'success') {
        setmenuData(result.data)
        setImageUrl(result.imageUrl);
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }
      else {
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }

    }).catch((error) => {
      setTimeout(() => {
        setisLoading(false)
      }, 500)
    })
  }
  const MobileMenuModal = () => {
    contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
  }

  return (<>
    <BrowserView>
      <header className="header">
        <div className="container-fluid">
          <div className="header-left">
            <nav className="navbar navbar-expand-lg navbar-light">
              <ul className="navbar-nav ">
                <Menu menuData={menuData} isLoading={isLoading} imageUrl={imageUrl}></Menu>
              </ul>
            </nav>
            <ul className="header-social">
              {contextValues.settingData.facebook_url && (
                <li><a href={contextValues.settingData.facebook_url} target='_blank'><i className="ri-facebook-box-fill"></i></a></li>)}
              {contextValues.settingData.instagram_url && (
                <li><a href={contextValues.settingData.instagram_url} target='_blank'><i className="ri-instagram-fill"></i></a></li>)}
                  {contextValues.settingData.linkedin_url && (
                <li><a href={contextValues.settingData.linkedin_url} target='_blank'><i className="ri-linkedin-fill"></i></a></li>)}
              {contextValues.settingData.twitter_url && (
                <li><a href={contextValues.settingData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
              {contextValues.settingData.youtube_url && (
                <li><a href={contextValues.settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>)}
            </ul>
          </div>
          <div className="header-right justify-content-end">
            <a href="/" className="logo">
              <img src="/img/logo.png"></img>
            </a>
          </div>
        </div>
      </header>

    </BrowserView>
    <MobileView>
      <header className="header">
        <div className="container-fluid">
          <div className="header-left">
            <a href="javascript:void(0)" className="sidemenu" onClick={() => { MobileMenuModal() }}><i class="ri-menu-2-fill"></i></a>
            <ul className="header-social">
              {contextValues.settingData.facebook_url && (
                <li><a href={contextValues.settingData.facebook_url} target='_blank'><i className="ri-facebook-box-fill"></i></a></li>)}
              {contextValues.settingData.instagram_url && (
                <li><a href={contextValues.settingData.instagram_url} target='_blank'><i className="ri-instagram-fill"></i></a></li>)}
              {contextValues.settingData.linkedin_url && (
                <li><a href={contextValues.settingData.linkedin_url} target='_blank'><i className="ri-linkedin-fill"></i></a></li>)}
              {contextValues.settingData.twitter_url && (
                <li><a href={contextValues.settingData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
              {contextValues.settingData.youtube_url && (
                <li><a href={contextValues.settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>)}
            </ul>
          </div>
          <div className="header-right justify-content-end">
            <a href="/" className="logo">
              <img src="/img/logo.png"></img>
            </a>
          </div>
        </div>
      </header>
    </MobileView>
    {contextValues.toggleMenuModal && <MobileMenu></MobileMenu>}
  </>)
}

export default Header