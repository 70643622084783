import { BrowserView, MobileView } from "react-device-detect"

const ProductBox=()=>{
    return(<>
    <BrowserView>
    <p>Product Box</p>
    </BrowserView>
     <MobileView>
     <p>Product Box</p>  
        
        </MobileView>   
       
        
        
        </>)
}

export default ProductBox