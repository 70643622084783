import { BrowserView, MobileView } from "react-device-detect"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const ContactUs=()=>{
    return(<>
    <BrowserView>
    <Header></Header>
    <p>Contact us</p>
    <Footer></Footer>
    </BrowserView>
   <MobileView>
   <Header></Header>
    <p>Contact us</p>
    <Footer></Footer>
   </MobileView>
    </>)
}

export default ContactUs